<template>
      <div>
        <DashboardCoreAppBar />
        <DashboardCoreDrawer />
        <DashboardCoreView />
    </div>
</template>

<script>
    import DashboardCoreAppBar from './layout/AppBar';
    import DashboardCoreDrawer from './layout/Drawer';
    import DashboardCoreView from './layout/View';

    export default {
        name: 'DashboardIndex',

        components: {
            DashboardCoreAppBar,
            DashboardCoreDrawer,
            DashboardCoreView,
        },
        mounted() {
            this.loadUserInfo();
        },
        data: () => ({
            expandOnHover: false,
        }),
        methods: {
            async loadUserInfo() {
                await this.$store.dispatch('user/getUserInfo');
            },
        }
    }
</script>

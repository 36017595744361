<template>
    <v-main>
        <keep-alive>
            <router-view />
        </keep-alive>
        <!-- <DashboardCoreFooter /> -->
    </v-main>
</template>

<script>
export default {
    name: "DashboardCoreView",

    components: {
        //   DashboardCoreFooter: () => import('./Footer'),
    },
};
</script>

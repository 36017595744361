<template>
    <v-navigation-drawer
        id="core-navigation-drawer"
        v-model="drawer"
        :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
        :expand-on-hover="expandOnHover"
        :right="$vuetify.rtl"
        src="@/assets/images/admin/sidebar-bg.jpeg"
        mobile-breakpoint="960"
        app
        width="230"
        v-bind="$attrs"
    >
    <template v-slot:img="props">
        <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
        />
    </template>

    <v-divider class="mb-1" />

    <v-list
        dense
        nav
    >
        <v-list-item>
            <v-list-item-avatar
                class="align-self-center"
                color="white"
                contain
                v-if="userInfo.avatar != ''"
            >
                <v-img :src="userInfo.avatar" />
            </v-list-item-avatar>
            <div class="profile-avatar" v-else>
                {{ getTypeFirstLetter(profile.title) }}
            </div>
            <v-list-item-content>
                <v-list-item-title
                    class="profile-title"
                    v-text="profile.title"
                />
            </v-list-item-content>
        </v-list-item>
    </v-list>

    <!-- <v-list
        dense
        nav
    >
        <v-list-item>
            <div class="draw-logo">
                <v-img
                    src="@/assets/images/common/logo_white_no_text.png"
                    max-width="140"
                ></v-img>
            </div>
        </v-list-item>
    </v-list> -->

    <v-divider class="mb-2" />

    <v-list
        nav
    >
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />

        <template v-for="(item, i) in computedItems">
        <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
        >
            <!--  -->
        </base-item-group>

        <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
        />
        </template>

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
    </v-list>

    <template v-slot:append>
        <!-- <base-item
        :item="{
            title: $t('upgrade'),
            icon: 'mdi-package-up',
            to: '/upgrade',
        }"
        /> -->
        <div class="logo">酷客编程</div>
        <div class="love">Made With <v-icon color="red">mdi-heart</v-icon></div>
    </template>
    </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters } from 'vuex';
import { getTypeFirstLetter } from "@/utils/util";

export default {
    name: 'DashboardCoreDrawer',
    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        items: [
            // Dashboard总览
            {
                icon: 'mdi-view-dashboard',
                title: 'menu.dashboard',
                to: '/dashboard',
            },
            // 教学管理
            {
            //   icon: 'mdi-account-multiple',
                icon: 'mdi-calendar-month',
                title: 'menu.education',
                // 使用二级目录的话，此处必须要加group，
                // 注意：group的路径必须为绝对路径
                //      children中的to为相对路径
                group: '/education-mgr',
                children: [
                    { title: 'menu.education-class', to: 'class-list' },
                    { title: 'menu.education-student', to: 'student-list' },
                    //   { title: 'menu.education-teacher', to: 'teacher' },
                ]
            },
            // 课程管理
            {
                icon: 'mdi-clipboard-text-outline',
                title: 'menu.course',
                group: '/course-mgr',
                children: [
                    { title: 'menu.course-topic', to: 'topic-list' },
                    { title: 'menu.course-course', to: 'course-list' },
                    //   { title: 'menu.course-practice', to: 'practice-list' },
                ]
            },
            // 资源管理
            {
                icon: 'mdi-format-font',
                title: 'menu.resource',
                group: '/resource-mgr',
                children: [
                    { title: 'menu.resource-pic', to: 'picture' },
                    { title: 'menu.resource-sound', to: 'sound' },
                ]
            },
            // 测评管理
            {
            //   icon: 'mdi-history',
                icon: 'mdi-calendar-check',
                title: 'menu.exam',
                group: '/exam-mgr',
                children: [
                    { title: 'menu.exam-question', to: 'question-lib' },
                    { title: 'menu.exam-paper',     to: 'paper-list' },
                    { title: 'menu.exam-evaluation', to: 'exam-list' },
                ]
            },
            // 机构管理
            {
            //   icon: 'mdi-calendar-month',
                icon: 'mdi-cog-outline',
                title: 'menu.institution',
                group: '/institution-mgr',
                children: [
                    { title: 'menu.institution-user', to: 'user-list' },
                    { title: 'menu.institution-role', to: 'role-list' },
                    // { title: 'menu.institution-campus', to: 'campus' },
                ]
            },
            // 账号设置，我的账号
            {
                icon: 'mdi-account-cog',
                title: 'menu.profile',
                to: '/profile',
            },
        ],
    }),
    computed: {
        ...mapGetters([
            'barColor',
            'barImage',
            'userInfo',
        ]),
        drawer: {
            get () {
                return this.$store.state.admin.drawer
            },
            set (val) {
                this.$store.commit('admin/SetDrawer', val)
            },
        },
        computedItems () {
            return this.items.map(this.mapItem)
        },
        profile () {
            return {
                // avatar: true,
                // title: this.$t('avatar'),
                avatar: this.userInfo.avatar,
                title: this.userInfo.nickname != "" ? this.userInfo.nickname : this.userInfo.name
            }
        },
    },
    methods: {
        getTypeFirstLetter,
        mapItem (item) {
            return {
                ...item,
                children: item.children ? item.children.map(this.mapItem) : undefined,
                title: this.$t(item.title),
            }
        },
    },
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px

</style>

<style lang="scss" scoped>
.profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    font-size: 18px;
    // font-weight: bold;
    color: #fff;
    // background-color: orange;
    background-color: #AB47BC;
    border-radius: 60px;
    margin: 8px 10px 8px 0;
}
.profile-title {
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 20px !important;
}
.logo {
    color: #efefef;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.love {
    color: #efefef;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.draw-logo {
    display: flex;
    margin-left: 7px;
}
</style>
